import React from "react";
import { motion } from "framer-motion";
//Leaflet
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { MapContainer, TileLayer } from "react-leaflet";
import { Marker } from "react-leaflet";
import { Popup } from "react-leaflet";


let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;
// const position = [37.0902, -93.7129];

// const position = [32.06811444294815, 118.77883719385952];
const position = [32.071830571296246, 118.77680704368314];
// 32.09415, 118.90289
// 32.068005136329404, 118.77830522329796
// 32.068214452731354, 118.7782685655993
//xianlin
// 32.103637102621356, 118.92607399650196
//Gulou
// 32.071830571296246, 118.77680704368314



const GulouMap = () => {
  const mapStyles = {
    width: '600px',
    height: '450px',
    border: '0'
  };

  return (
    
    <div className="contact-usss"><br></br><br></br><br></br>Contact Us
    <article className="section-10  flex-container flex-column">
      
      
      <a href="https://maps.app.goo.gl/h1QC8XhcTUczh6XV8" target="_blank">
      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.792093690111!2d118.7782738247596!3d32.076379169548446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzLCsDA0JzU3LjEiTiAxMTgNzcgyNDc!5e0!3m2!1sen!2sus!4v1692631534523!5m2!1sen!2sus"
        style={mapStyles}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      /> */}
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3381.047314414844!2d118.77827929999997!3d32.067969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35b58d0a06ca57bf%3A0xea003c299202bc55!2sAL-JANNAT%20Indian%20Restaurant!5e0!3m2!1sen!2sus!4v1724217015529!5m2!1sen!2sus" 
        width="600" 
        height="450" 
        style={mapStyles} 
        allowfullscreen="" 
        loading="lazy" 
        referrerpolicy="no-referrer-when-downgrade">

      </iframe>
    </a>
      
      <motion.div
        className="contact-emails"
        initial={{ opacity: 0, translateX: 300 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: 300 }}
        transition={{ duration: 2 }}
      >
        <div className="contact-ussss">Service Hours</div>
        <section>
          <ul>
            <p> - Directions:
                Subway line 1,  Xuanwumen station, Exit 1. Then follow the map
                </p>
                <br></br>
            <li>- Phone: +86 25 5223 5776</li>
            <li>- Hours: 11:00am - 10:00pm </li>
          </ul>
        </section>
        <br>
        </br>
        <br>
        </br>
      
        <section>
          {/* <h3>Feedback and Suggestions</h3>
          <p>We value your feedback and are always striving to improve. If you have any suggestions, comments, or ideas on how we can enhance our offerings, please let us know.</p>
          <br></br>
          <ul>
            <li>- Email: our contact number</li> */}
            {/* <li>- Phone: +86 25 5223 5776</li> */}

          {/* </ul> */}
        </section>
      </motion.div>
    </article>
    </div>
  );
}


export default GulouMap;
