import React from 'react';
import './about.css';
// import  // Assuming you save your CSS in this file
import sid from '../../assets/images/about-us/sid.jpg'
import gudu from '../../assets/images/gudu-removebg-preview.png'
import dharam from '../../assets/images/dharam-removebg-preview.png'
import parveen from '../../assets/images/parveen_jianging-removebg-preview.png'
import pradeep from '../../assets/images/pradeep-removebg-preview.png'
import suri from '../../assets/images/suri.jpg'

// import profileImage from './path-to-your-image.png'; // Update the path as per your project structure


const employees = [
    // { name: 'Narender', role: 'Manager', image: narender },
    { name: 'Surinder Singh', role: 'Chef', image: suri },

    { name: 'Gudu', role: 'Chef', image: gudu }, // Add different image paths here
    { name: 'Pradeep', role: 'waiter', image: pradeep }, // Replace 'sid' with actual image paths
    { name: 'Dharam', role: 'Waiter', image: dharam },
];

const XianlinStaff = () => {
    return (
        <div className="profile-container">
        <h2>Our Team</h2> {/* Add your heading here */}

        <br></br>
            <div className="profile-card">
                <img src={parveen} alt="John" className="profile-image" />
                <div className="profile-details">
                    <p className="profile-role">Parveen</p>
                    <p className="profile-description">Manager</p>
                </div>
            </div>
            <br></br>

            <br />
            <div className="profile-grid">
                {employees.map((employee, index) => (
                    <div key={index} className="profile-card">
                        <img src={employee.image} alt={employee.name} className="profile-image" />
                        <div className="profile-details">
                            <p className="profile-role">{employee.name}</p>
                            <p className="profile-description">{employee.role}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default XianlinStaff;
