import React from 'react';
import './about.css';
// import  // Assuming you save your CSS in this file
import sid from '../../assets/images/about-us/sid.jpg'
import narender from '../../assets/images/narender.png'
import sandeep from '../../assets/images/sandeep.png'
import jay from '../../assets/images/jay.png'
import sanjay from '../../assets/images/sanjay.png'
import cheff from '../../assets/images/cheff.png'
import bandari from '../../assets/images/Bandari.jpg'
import sandeepk from '../../assets/images/sandeep gulou.jpg'



// import profileImage from './path-to-your-image.png'; // Update the path as per your project structure


const employees = [
    // { name: 'Narender', role: 'Manager', image: narender },
    // { name: 'Sanjay', role: 'Waiter', image: sanjay }, // Add different image paths here
    { name: 'sandeep', role: 'Chef', image: sandeep }, // Replace 'sid' with actual image paths
    { name: 'jay', role: 'Waiter', image: jay },
    { name: 'Sandeepk', role: 'Chef', image: sandeepk },
    { name: 'bandari', role: 'Chef', image: bandari },
    { name: 'cheff', role: 'Chef', image: cheff }
];


const ProfileCard = () => {
    return (
        <div className="profile-container">
        <h2>Our Team</h2> {/* Add your heading here */}

             <br></br>
            <div className="profile-card">
                <img src={narender} alt="John" className="profile-image" />
                <div className="profile-details">
                    <p className="profile-role">Narender</p>
                    <p className="profile-description">Manager</p>
                </div>
            </div>
            {/*<div className="profile-grid">
                {Array(4).fill().map((_, i) => (
                    <div key={i} className="profile-card">
                        <img src={sid} alt="John" className="profile-image" />
                        <div className="profile-details">
                            <p className="profile-role">Sanjay</p>
                            <p className="profile-description">Waiter</p>
                        </div>
                    </div>
                ))}
            </div> */}

            <br />
            <div className="profile-grid">
                {employees.map((employee, index) => (
                    <div key={index} className="profile-card">
                        <img src={employee.image} alt={employee.name} className="profile-image" />
                        <div className="profile-details">
                            <p className="profile-role">{employee.name}</p>
                            <p className="profile-description">{employee.role}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProfileCard;
