import React from 'react'
//images
import SlideOne from '../../assets/images/img-one.jpeg'
import SlideTwo from '../../assets/images/carbonara.jpeg'
import SlideThree from '../../assets/images/img-three.jpeg'
import SlideFour from '../../assets/images/img-four.jpeg'


const JiangningAboutUs = () => {
  return (
    <article className="about-us">
      <section className="content">
        <h2>Jiangning</h2>
        <h3 className='sub-title'>More than delicious food</h3>
        <p>
        Aljannat Indian Restaurant has been dedicated to bringing the rich, diverse flavors of traditional Indian, Pakistani, and Afghan cuisine. We pride ourselves on unique branding and a deep understanding of what our customers crave, transforming culinary desires into unforgettable taste experiences. Aljannat Indian Restaurant has got you covered. We value every customer because it is you who makes us special. Come experience the vibrant tastes and warm hospitality that have made us a cherished dining destination for over four decades.
        </p>
        <div className="section-one-glass"></div>
      </section>
      <section className="images">
        <img src={SlideOne} alt="" aria-hidden="true" />
        <img src={SlideTwo} alt="" aria-hidden="true" />
        <img src={SlideThree} alt="" aria-hidden="true" />
        <img src={SlideFour} alt="" aria-hidden="true" />
      </section>
    </article>
  )
}

export default JiangningAboutUs;
