// import React from "react";
// import { motion } from "framer-motion";
// //Leaflet
// import L from "leaflet";
// import icon from "leaflet/dist/images/marker-icon.png";
// import iconShadow from "leaflet/dist/images/marker-shadow.png";
// import { MapContainer, TileLayer } from "react-leaflet";
// import { Marker } from "react-leaflet";
// import { Popup } from "react-leaflet";


// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
// });
// L.Marker.prototype.options.icon = DefaultIcon;
// // const position = [37.0902, -93.7129];

// // const position = [32.06811444294815, 118.77883719385952];
// const position = [32.068214452731354, 118.7782685655993];

// // 32.068214452731354, 118.7782685655993



// const ContactLanding = () => {
//   return (
//     <article className="section-10  flex-container flex-column">
//       <motion.div
//         className="map"
//         initial={{ opacity: 0, translateX: -300 }}
//         whileInView={{ opacity: 1, translateX: 0 }}
//         exit={{ opacity: 0, translateX: -300 }}
//         transition={{ duration: 2 }}
//       >
//         <MapContainer
//           id="map"
//           center={position}
//           zoom={9}
//           scrollWheelZoom={false}
//         >
//           <TileLayer
//             attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           />
//           <Marker position={position}>
//             <Popup>
//               A pretty CSS3 popup. <br /> Easily customizable.
//             </Popup>
//           </Marker>
//         </MapContainer>
//       </motion.div>
//       <motion.div
//         className="contact-emails"
//         initial={{ opacity: 0, translateX: 300 }}
//         whileInView={{ opacity: 1, translateX: 0 }}
//         exit={{ opacity: 0, translateX: 300 }}
//         transition={{ duration: 2 }}
//       >
//         <h3>Contact Us</h3>
//         <p>Have a question, suggestion, or just want to say hello? We'd love to hear from you!</p>
//         <section>
//           <h4>Customer Support:</h4>
//           <p>Our dedicated customer support team is ready to assist you with any inquiries you may have.</p>
//           <ul>
//             <li>- Email: our contact number</li>
//             <li>- Phone: +86 25 5223 5776</li>
//             <li>- Hours: 11:00am - 10:00pm </li>
//           </ul>
//         </section>
//         <section>
//           <h4>General Inquiries:</h4>
//           <p>If you have general questions about our company, partnerships, or anything else, feel free to get in touch.</p>
//           <ul>
//             <li>- Email: our contact number</li>
//           </ul>
//         </section>
//         <section>
//           <h4>Feedback and Suggestions:</h4>
//           <p>We value your feedback and are always striving to improve. If you have any suggestions, comments, or ideas on how we can enhance our offerings, please let us know.</p>
//           <ul>
//             <li>- Email: our contact number</li>
//           </ul>
//         </section>
//       </motion.div>
//     </article>
//   );
// }


// export default ContactLanding;














import React from "react";
import { motion } from "framer-motion";
//Leaflet
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { MapContainer, TileLayer } from "react-leaflet";
import { Marker } from "react-leaflet";
import { Popup } from "react-leaflet";


let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;
// const position = [37.0902, -93.7129];

// const position = [32.06811444294815, 118.77883719385952];
const position = [32.071830571296246, 118.77680704368314];
// 32.09415, 118.90289
// 32.068005136329404, 118.77830522329796
// 32.068214452731354, 118.7782685655993
//xianlin
// 32.103637102621356, 118.92607399650196
//Gulou
// 32.071830571296246, 118.77680704368314



const ContactLanding = () => {
  const mapStyles = {
    width: '600px',
    height: '450px',
    border: '0'
  };

  return (
    <article className="section-10  flex-container flex-column">
      <a href="https://maps.app.goo.gl/VB6bWsdgyiYWDTik6" target="_blank">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3381.047314414844!2d118.77827929999997!3d32.067969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35b58d0a06ca57bf%3A0xea003c299202bc55!2sAL-JANNAT%20Indian%20Restaurant!5e0!3m2!1sen!2sus!4v1724217015529!5m2!1sen!2sus" 
        width="600" 
        height="450" 
        style={mapStyles} 
        allowfullscreen="" 
        loading="lazy" 
        referrerpolicy="no-referrer-when-downgrade">

      </iframe>
    </a>
      
      <motion.div
        className="contact-emails"
        initial={{ opacity: 0, translateX: 300 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: 300 }}
        transition={{ duration: 2 }}
      >
        <h3>Service Hours</h3>
        <section>
          <ul>
            <p> - Directions:
                Subway line 1,  Xuanwumen station, Exit 1. Then follow the map
                </p>
                <br></br>
            <li>- Phone: +86 25 5223 5776</li>
            <li>- Hours: 11:00am - 10:00pm </li>
          </ul>
        </section>
        <br>
        </br>
        <br>
        </br>
      
        <section>
          <h3>Feedback and Suggestions</h3>
          <p>We value your feedback and are always striving to improve. If you have any suggestions, comments, or ideas on how we can enhance our offerings, please let us know.</p>
          <br></br>
          <ul>
            <li>- Email: our contact number</li>
            <li>- Phone: +86 25 5223 5776</li>

          </ul>
        </section>
      </motion.div>
    </article>
  );
}


export default ContactLanding;
