import React, { useEffect } from "react";
import { motion } from "framer-motion";
//Components
import ScrollBtn from "../../helpers/ScrollBtn";
import AboutCollaboration from "./AboutCollaboration";
import AboutCareers from "./AboutCareers";
import GulouAboutUs from "./GulouAboutUs";
import AboutDedication from "./AboutDedication";
import AboutCustomers from "./AboutCustomers";
import ContactLanding from "../landing/ContactLanding";
import GulouMap from "./GulouMap";
import GulouStaff from "./GulouStaff";
import JiangningAboutUs from "./JiangningAboutUs";
import JiangningStaff from "./JiangningStaff";
import JiangningMap from "./JiangningMap";

const Jiangning = () => {
  useEffect(() => {
    document.title = "About | Al-Jannat Restaurants";
  }, []);
  return (
    <motion.main
      className="about"
      initial={{ opacity: 0, translateX: -300 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -300 }}
      transition={{ duration: 1 }}>
      <JiangningAboutUs />
      {/* <AboutCareers /> */}
      <br></br>

      <br></br>

      <br></br>

      <br></br>
      {/* <AboutCollaboration /> */}
      <AboutCustomers />
      <JiangningStaff />
      {/* <AboutDedication /> */}

      

      <JiangningMap />
      <ScrollBtn />
    </motion.main>
  );
}

export default Jiangning;







